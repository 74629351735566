<template>
  <div>
    <modal-edit-location-utilization
      v-if="showModalEditLocationUtilization"
      :showModal="showModalEditLocationUtilization"
      :onClickCancel="closeEditModal"
    />

    <div>
      <label class="subtitle">
        <input
          type="checkbox"
          class="checkbox"
          @change="saveYieldSettings"
          v-model="mYieldSettings.ApplyLocationUtilization"
          :checked="mYieldSettings.ApplyLocationUtilization"
        />
        Use location utilization
      </label>
    </div>

    <div class="has-text-right">
      <a
        @click="setShowEditLocationUtilization"
        v-if="mYieldSettings.ApplyLocationUtilization"
        class="is-size-7"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </span>
        <span>Edit</span>
      </a>
    </div>

    <table
      v-if="mYieldSettings.ApplyLocationUtilization"
      class="table is-fullwidth is-striped is-narrow"
    >
      <tbody>
        <tr>
          <td>Calculation type</td>
          <td>
            <span
              v-if="
                yieldSettingsState.yieldSettings
                  .LocationUtilizationCalculationType === 0
              "
              >Based on fixed hours</span
            >
            <span
              v-if="
                yieldSettingsState.yieldSettings
                  .LocationUtilizationCalculationType === 1
              "
              >Based on actual opening hours</span
            >
          </td>
        </tr>
        <tr
          v-if="
            yieldSettingsState.yieldSettings
              .LocationUtilizationCalculationType === 0
          "
        >
          <td>Default hours for calculation</td>
          <td>
            {{
              yieldSettingsState.yieldSettings.LocationUtilizationDefaultHours
            }}
            hours per day
          </td>
        </tr>
        <tr v-if="mYieldSettings.ApplyLocationUtilization">
          <td>Apply discount or surcharge</td>
          <td>
            <div>
              0% occupancy apply
              {{
                roundToZero(
                  yieldSettingsState.yieldSettings
                    .LocationUtilizationBottomValue *
                    100 -
                    100
                )
              }}% discount
            </div>
            <div>
              100% occupancy apply
              {{
                roundToZero(
                  yieldSettingsState.yieldSettings.LocationUtilizationTopValue *
                    100 -
                    100
                )
              }}% surcharge
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <article
      v-if="!mYieldSettings.ApplyLocationUtilization"
      class="message is-info has-margin-top"
    >
      <div class="message-body">
        Activate if you want to apply a discount or surcharge based on the
        utilization of the location.
      </div>
    </article>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import yieldProvider from '@/providers/yield'

const ModalEditYieldLocationUtilization = () =>
  import('@/components/Yield/ModalEditYieldLocationUtilization')

export default {
  components: {
    'modal-edit-location-utilization': ModalEditYieldLocationUtilization,
  },

  data() {
    return {
      mYieldSettings: null,
      showModalEditLocationUtilization: false,
    }
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState']),
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },

    saveYieldSettings() {
      let self = this

      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      yieldProvider.methods
        .updateLocationYieldSettings(self.mYieldSettings)
        .then((response) => {
          if (response.status === 204) {
            self.setYieldSettingsState(self.yieldSettingsState)
          }
        })
    },

    setShowEditLocationUtilization() {
      this.showModalEditLocationUtilization = true
    },

    closeEditModal() {
      this.showModalEditLocationUtilization = false
    },
  },
}
</script>

<style></style>
