var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showModalEditLocationUtilization)?_c('modal-edit-location-utilization',{attrs:{"showModal":_vm.showModalEditLocationUtilization,"onClickCancel":_vm.closeEditModal}}):_vm._e(),_c('div',[_c('label',{staticClass:"subtitle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mYieldSettings.ApplyLocationUtilization),expression:"mYieldSettings.ApplyLocationUtilization"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":_vm.mYieldSettings.ApplyLocationUtilization,"checked":Array.isArray(_vm.mYieldSettings.ApplyLocationUtilization)?_vm._i(_vm.mYieldSettings.ApplyLocationUtilization,null)>-1:(_vm.mYieldSettings.ApplyLocationUtilization)},on:{"change":[function($event){var $$a=_vm.mYieldSettings.ApplyLocationUtilization,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.mYieldSettings, "ApplyLocationUtilization", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.mYieldSettings, "ApplyLocationUtilization", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.mYieldSettings, "ApplyLocationUtilization", $$c)}},_vm.saveYieldSettings]}}),_vm._v(" Use location utilization ")])]),_c('div',{staticClass:"has-text-right"},[(_vm.mYieldSettings.ApplyLocationUtilization)?_c('a',{staticClass:"is-size-7",on:{"click":_vm.setShowEditLocationUtilization}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'edit']}})],1),_c('span',[_vm._v("Edit")])]):_vm._e()]),(_vm.mYieldSettings.ApplyLocationUtilization)?_c('table',{staticClass:"table is-fullwidth is-striped is-narrow"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Calculation type")]),_c('td',[(
              _vm.yieldSettingsState.yieldSettings
                .LocationUtilizationCalculationType === 0
            )?_c('span',[_vm._v("Based on fixed hours")]):_vm._e(),(
              _vm.yieldSettingsState.yieldSettings
                .LocationUtilizationCalculationType === 1
            )?_c('span',[_vm._v("Based on actual opening hours")]):_vm._e()])]),(
          _vm.yieldSettingsState.yieldSettings
            .LocationUtilizationCalculationType === 0
        )?_c('tr',[_c('td',[_vm._v("Default hours for calculation")]),_c('td',[_vm._v(" "+_vm._s(_vm.yieldSettingsState.yieldSettings.LocationUtilizationDefaultHours)+" hours per day ")])]):_vm._e(),(_vm.mYieldSettings.ApplyLocationUtilization)?_c('tr',[_c('td',[_vm._v("Apply discount or surcharge")]),_c('td',[_c('div',[_vm._v(" 0% occupancy apply "+_vm._s(_vm.roundToZero( _vm.yieldSettingsState.yieldSettings .LocationUtilizationBottomValue * 100 - 100 ))+"% discount ")]),_c('div',[_vm._v(" 100% occupancy apply "+_vm._s(_vm.roundToZero( _vm.yieldSettingsState.yieldSettings.LocationUtilizationTopValue * 100 - 100 ))+"% surcharge ")])])]):_vm._e()])]):_vm._e(),(!_vm.mYieldSettings.ApplyLocationUtilization)?_c('article',{staticClass:"message is-info has-margin-top"},[_c('div',{staticClass:"message-body"},[_vm._v(" Activate if you want to apply a discount or surcharge based on the utilization of the location. ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }